import type { RouteLocationNormalized } from '#vue-router'
import type { AgnosticSession } from 'shared/types'

type Page = 'login' | 'shipping' | 'billing' | 'review' | 'thank-you'

const { getURL } = useURL()

const isCartExist = (session: AgnosticSession) => {
  return session && session.cart
}

const isLoginPage = (session: AgnosticSession, page: Page) => {
  return session.isRegistered && page === 'login'
}

const isShippingMethodValid = (page: Page, shippingMethodInvalid?: boolean) => {
  return !(shippingMethodInvalid !== undefined && shippingMethodInvalid && page !== 'shipping' && page !== 'login')
}

const isShippingMethodPriceChanged = (page: Page, shippingMethodPriceChanged: boolean) => {
  return shippingMethodPriceChanged && page !== 'shipping' && page !== 'login'
}

const isShippingAddressExist = (page: Page, clientSession: AgnosticSession) => {
  return page === 'billing' && Boolean(clientSession.cart?.shippingAddress)
}

const isShippingAndBillingAddressExist = (clientSession: AgnosticSession) => {
  return Boolean(clientSession.cart?.shippingAddress) && Boolean(clientSession.cart?.billingAddress)
}

const redirectToCart = () => {
  navigateTo(getURL({ name: 'cart' }), { redirectCode: 301 })
}

const proceedToCheckout = (from: RouteLocationNormalized) => {
  if (from && from.name?.toString().includes('checkout-shipping')) {
    return redirectToCart()
  } else {
    return navigateTo(
      getURL({
        name: 'checkout-shipping'
      })
    )
  }
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) {
    return
  }

  const api = useEcommerce()
  const verificationResult = await api.verifyCheckout()
  const { session: currentSession, updateSession, forceLoad } = useSession()
  const page = (typeof to.meta.key === 'function' ? to.meta.key(to) : to.meta.key) as Page

  // auto redirect in axios transform response on session expired
  if (!verificationResult.ok && verificationResult.error?.__sidexp__) {
    return
  }

  if (!verificationResult.ok) {
    return redirectToCart()
  }

  const {
    payload: { session, checkoutEnabled, shippingMethodInvalid, shippingMethodPriceChanged }
  } = verificationResult

  if (!currentSession.value || !checkoutEnabled || session.isGuest || !isCartExist(session)) {
    return redirectToCart()
  }

  if (page === 'thank-you') {
    updateSession(await forceLoad())
    return
  }

  updateSession({ ...session, wishlist: session.wishlist || currentSession.value.wishlist })

  if (isLoginPage(session, page)) {
    return proceedToCheckout(from)
  }

  if (!isShippingMethodValid(page, shippingMethodInvalid)) {
    return navigateTo(
      getURL({
        name: 'checkout-shipping',
        query: { err: 'shippingMethodInvalid' }
      })
    )
  }

  if (isShippingMethodPriceChanged(page, shippingMethodPriceChanged)) {
    return navigateTo(
      getURL({
        name: 'checkout-shipping',
        query: { err: 'shippingMethodPriceChanged' }
      })
    )
  }

  if (!isShippingAddressExist(page, session)) {
    return redirectToCart()
  }

  if (page === 'review' && !isShippingAndBillingAddressExist(session)) {
    return redirectToCart()
  }

  if (page === 'review') {
    return navigateTo(
      getURL({
        name: 'checkout-billing'
      })
    )
  }
})
